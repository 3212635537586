import React, { useEffect, useState } from "react"
import Logo from "./logo"
import { Link } from "gatsby"
import { Slant as Hamburger } from 'hamburger-react'
import { AnimatePresence, motion } from "framer-motion"

function Menu({ isOpen, setIsOpen }) {
  const variants = {
    show: {
      opacity: 1,
      transition: { when: "beforeChildren", duration: 0.3 },
    },
    hide: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.6,
        when: "afterChildren",
      },
    },
  }

  const childVariants = {
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    hide: {
      opacity: 0,
      y: -10,
    },
    exit: {
      opacity: 0,
      y: -10,
      transition: {
        duration: 0.4,
      },
    },
    toRight: {
      y: -5,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
  }
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          variants={variants}
          animate={isOpen ? "show" : "hide"}
          initial="hide"
          exit="exit"
          className="fixed top-0 left-0 right-0 bottom-0 w-full bg-black z-20"
        >
          <motion.div
            className="flex flex-column"
            variants={childVariants}
          >
            <div className="flex flex-col fs30 justify-center items-center mx-auto h-screen">
              <motion.div variants={childVariants} className="mb-2" onClick={() => setIsOpen(!isOpen)}>
                <Link to="/" className="font-medium text-6xl">
                  Work
                </Link>
              </motion.div>

              <motion.div variants={childVariants} className="mb-2">
                <Link to="/insights" className="font-medium text-6xl" onClick={() => setIsOpen(!isOpen)}>
                  Insights
                </Link>
              </motion.div>
              <div className="mb-24"></div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

function Header({ isCase }) {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }
  }, [isOpen])
  return (
    <React.Fragment>
      <div className=" z-50 max-w-max px-3 rounded-full mx-auto py-2.5 bg-realGray-800 bg-opacity-50 backdrop-filter backdrop-blur-md w-full inline-block top-auto fixed bottom-6 shadow-lg left-0 right-0">
        <div className="flex justify-center items-center">

              <div className="">
                <Link to="/" activeClassName="bg-white text-black px-3 py-2 rounded-full" className="font-medium text-sm px-3 py-2">
                  Home
                </Link>

                <Link to="/work" activeClassName="bg-white text-black px-3 py-2 rounded-full" className="font-medium text-sm px-3 py-2">
                  Work
                </Link>
                <Link to="/wordmarks" activeClassName="bg-white text-black px-3 py-2 rounded-full" className="font-medium text-sm px-3 py-2">
                  Wordmarks
                </Link>
              </div>
          {/* <div className="hidden md:block">
            <motion.div className="flex" >
              {/*<motion.div>
                <Link
                  to="/"
                  activeClassName="bg-realGray-900"
                  className="mr-32 mb-1 hover:bg-realGray-900 px-2 py-1 rounded-md"
                >
                  Work
                </Link>
              </motion.div>
               <motion.div layoutId="bg-realGray-900">
                <Link
                  to="/process"
                  activeClassName="bg-realGray-900"
                  className="mr-4 mb-1 hover:bg-realGray-900 px-2 py-1 rounded-md"
                >
                  Process
                </Link>
              </motion.div> 
               <motion.div layoutId="bg-realGray-900">
                <Link
                  to="/play"
                  activeClassName="bg-realGray-900"
                  className="mr-6 mb-1 hover:bg-realGray-900 px-2 py-1 rounded-md"
                >
                  Sketchbook
                </Link>
              </motion.div> 
            </motion.div>
          </div> */}
          <motion.div className="ml-2">
            <a
              href="mailto:hi@lancedraws.com"
              className="font-medium  text-white leading-5 hover:bg-white hover:text-black transition-colors duration-500 inline-block bg-blue-600 rounded-full text-base py-1.5 px-4"
            >
              Get Started
            </a>
          </motion.div>
          <Menu isOpen={isOpen} setIsOpen={setIsOpen} label="Show menu"/>
          <button
            aria-label="Show menu"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="hidden"
          >
            <div className="relative z-50">
              <Hamburger toggled={isOpen} toggle={setIsOpen} size={19} color="white"/>
            </div>
          </button>
        </div>
      </div>
      </React.Fragment>
  )
}

export default Header
