import React from "react"
import Footer from "./footer"
import Header from "./header"
import { motion, AnimatePresence } from "framer-motion"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Casesmenu from "./casesmenu"

function Layout({ children, location }) {
  const duration = 0.3

  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: duration,
        delay: duration,
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: duration },
    },
  }
  const variantsWork = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: duration,
        delay: duration,
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: duration },
    },
  }

  return (
    <div className="">
      {location.pathname.startsWith("/work/") ? (
        <Header isCase={true} />
      ) : (
        <Header isCase={false} />
      )}
      {location.pathname.startsWith("/work") ? (
        <Casesmenu isCase={true} />
      ) : (
        <Casesmenu isCase={false} />
      )}

      <AnimatePresence>
        <motion.main
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          className=""
        >
          <div className="mar ">{children}</div>
        </motion.main>
      </AnimatePresence>
      <Footer />
    </div>
  )
}

export default Layout
