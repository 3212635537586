import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

export default function Casesmenu({ isCase }) {
  const variants = {
    show: {
      opacity: 1,
      x:0,
      transition: {  duration: .5, delay:.3 },
    },
    hide: {
      x:-20,
      opacity: 0,  transition: {  duration: .5, delay:.3 },
    },
    exit: {
      x:20,
      opacity: 0,
      transition: {
        duration: 0.5,
        when: "afterChildren",
      },
    },
  }
  return (
    <AnimatePresence  >
      {isCase && (
        <motion.div
        key="casemenu"
          variants={variants}
          animate={isCase ? "show" : "hide"}
          initial="hide"
          exit="exit"
          className="fixed left-4 md:left-8 top-4 bg-white bg-opacity-20 border border-solid border-opacity-10 border-white backdrop-filter w-min backdrop-blur-md rounded-full z-50"
        >
          <motion.div className="inline-grid flex-wrap justify-center justify-items-center pl-4 pr-7 py-3 grid-flow-col">
            <motion.div>
              <Link
                to="/work/voiceflow"
                activeClassName="scale-110 transform block transition-all"
              >
                <StaticImage
                  className="w-10 h-10 rounded-full -mr-4 hover:mr-0 transition-all duration-300 hover:scale-110 transform inline-block"
                  src="../images/cases/voiceflow_FI_w.png"
                />
              </Link>
            </motion.div>
            <motion.div>
              <Link
                to="/work/bankjoy"
                activeClassName="scale-110 transform block transition-all"
              >
                <StaticImage
                  className="w-10 h-10 rounded-full -mr-4 hover:mr-0 transition-all duration-300 hover:scale-110 transform inline-block"
                  src="../images/cases/bankjoy_FI_w.png"
                />
              </Link>
            </motion.div>
            <motion.div>
              <Link
                to="/work/athlete-plus"
                activeClassName="scale-110 transform block transition-all"
              >
                <StaticImage
                  className="w-10 h-10 rounded-full -mr-4 hover:mr-0 transition-all duration-300 hover:scale-110 transform inline-block"
                  src="../images/cases/athleteplus_FI_w.png"
                />
              </Link>
            </motion.div>
            <motion.div>
              <Link
                to="/work/panchos"
                activeClassName="scale-110 transform block transition-all"
              >
                <StaticImage
                  className="w-10 h-10 rounded-full -mr-4 hover:mr-0 transition-all duration-300 hover:scale-110 transform inline-block"
                  src="../images/cases/panchos_FI_w.png"
                />
              </Link>
            </motion.div>
            <motion.div>
              <Link
                to="/work/juicy-beast"
                activeClassName="scale-110 transform block transition-all"
              >
                <StaticImage
                  className="w-10 h-10 rounded-full -mr-4 hover:mr-0 transition-all duration-300 hover:scale-110 transform inline-block"
                  src="../images/cases/juicybeast_FI_w.png"
                />
              </Link>
            </motion.div>
            <motion.div>
              <Link
                to="/work/pinjour"
                activeClassName="scale-110 transform block transition-all"
              >
                <StaticImage
                  className="w-10 h-10 rounded-full -mr-4 hover:mr-0 transition-all duration-300 hover:scale-110 transform inline-block"
                  src="../images/cases/pinjour_FI_w.png"
                />
              </Link>
            </motion.div>
            <motion.div>
              <Link
                to="/work/casper"
                activeClassName="scale-110 transform block transition-all"
              >
                <StaticImage
                  className="w-10 h-10 rounded-full -mr-4 hover:mr-0 transition-all duration-300 hover:scale-110 transform inline-block"
                  src="../images/cases/casper_FI_w.png"
                />
              </Link>
            </motion.div>
            <motion.div>
              <Link
                to="/work/chimney"
                activeClassName="scale-110 transform block transition-all"
              >
                <StaticImage
                  className="w-10 h-10 rounded-full -mr-4 hover:ml-4 transition-all duration-300 hover:scale-110 transform inline-block"
                  src="../images/cases/chimney_FI_w.png"
                />
              </Link>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
