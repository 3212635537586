import React from "react"
import Logo from "../components/logo"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <div className="mar pb-12 py-4">
      <div className="flex flex-col md:flex-row">
        <div className="mr-auto  ">
          <Link to="/" className="text-current">
            <Logo width={75} />
          </Link>
          <div className="flex flex-col mb-7 md:mb-0">
            <a className="text-base text-current" href="mailto:hi@lancedraws.com">
              hi@lancedraws.com
            </a>
          </div>
        </div>

        <div className="flex flex-col md:mr-16 mb-5 md:mb-0">
          <div className="mb-3 font-medium text-base">Pages</div>
          <Link to="/" className="text-sm mb-1 text-current">
            Home
          </Link>
          <Link to="/work" className="text-sm mb-1 text-current">
            Work
          </Link>
          <Link to="/wordmarks" className="text-sm mb-1 text-current">
            Wordmarks
          </Link>
          {/* <div className="flex items-center">
            <Link
              to="/insights"
              className="inline-block text-sm"
            >
              Insights{" "}
            </Link>
            <span className="text-xs py-0.5 px-1 bg-realGray-900 text-realGray-300 rounded-sm ml-1">
              Soon
            </span>
          </div> */}
        </div>
        <div className="flex flex-col md:mr-16 mb-5 md:mb-0">
          <div className="mb-3 font-medium text-base">Selected Work</div>
          <Link
            to="/work/voiceflow"
            className="text-sm mb-1 text-current"
          >
            Voiceflow
          </Link>
          <Link
            to="/work/bankjoy"
            className="text-sm mb-1 text-current"
          >
            Bankjoy
          </Link>
          <Link
            to="/work/pinjour"
            className="text-sm mb-1 text-current"
          >
            Pinjour
          </Link>
          <Link
            to="/work/casper"
            className="text-sm mb-1 text-current"
          >
            Casper
          </Link>
        </div>

        <div className="flex flex-col mb-5 md:mb-0 md:mr-3">
          <div className="mb-3 font-medium text-base">Socials</div>
          {/* <Link to="/">Case Studies</Link> */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://dribbble.com/lancedraws"
            className="text-sm mb-1 text-current"
          >
            Dribbble
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://linkedin.com/in/lance-designs"
            className="text-sm mb-1 text-current"
          >
            Linkedin
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/lancedraws"
            className="text-sm mb-1 text-current"
          >
            Twitter
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:hi@lancedraws.com"
            className="text-sm mb-1 text-current"
          >
            Email
          </a>
        </div>
      </div>
      <div className="flex mt-16 md:mt-28 justify-between">
        <p className=" text-xs text-current">© Lance 2022 — All Rights Reserved.</p>
      </div>
    </div>
  )
}
